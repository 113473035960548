import { AboutText } from "../components/AboutText";

export const AboutPage = () => {
  return (
    <div>
      <h1>About</h1>

      <AboutText />
    </div>
  );
};
